import React from 'react'
import logo from "../../../assets/media/footer.png"
import Footer_field from './Footer_field'

const Sec01 = () => {
  return (
    <div className='footer_column01'>
      <div>
      <a className="" href="/">
        <img src={logo} alt="main_logo" width={'200px'}/>
      </a>
      <div className='fields_div'>
        <Footer_field 
            svg_icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">  <path d="M14.2587 10.9467V12.9467C14.2595 13.1324 14.2215 13.3162 14.1471 13.4863C14.0727 13.6564 13.9636 13.8091 13.8268 13.9346C13.69 14.0602 13.5285 14.1557 13.3526 14.2152C13.1767 14.2747 12.9903 14.2968 12.8054 14.2801C10.754 14.0572 8.78341 13.3562 7.05208 12.2334C5.4413 11.2098 4.07564 9.84418 3.05208 8.2334C1.9254 6.4942 1.22424 4.51406 1.00541 2.4534C0.988751 2.26904 1.01066 2.08324 1.06974 1.90781C1.12883 1.73239 1.22379 1.57119 1.34859 1.43448C1.47339 1.29777 1.62528 1.18854 1.79461 1.11375C1.96393 1.03895 2.14697 1.00024 2.33208 1.00006H4.33208C4.65562 0.99688 4.96927 1.11145 5.21459 1.32242C5.4599 1.53339 5.62013 1.82636 5.66541 2.14673C5.74983 2.78678 5.90638 3.41522 6.13208 4.02006C6.22177 4.25868 6.24119 4.51801 6.18802 4.76732C6.13485 5.01663 6.01132 5.24547 5.83208 5.42673L4.98541 6.2734C5.93445 7.94243 7.31638 9.32436 8.98541 10.2734L9.83208 9.42673C10.0133 9.24749 10.2422 9.12396 10.4915 9.07079C10.7408 9.01762 11.0001 9.03704 11.2387 9.12673C11.8436 9.35243 12.472 9.50898 13.1121 9.5934C13.4359 9.63909 13.7317 9.8022 13.9431 10.0517C14.1545 10.3013 14.2669 10.6198 14.2587 10.9467Z" stroke="#EFEFEF" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>  </svg>} 
            data={<a style={{textDecoration:'none', color: '#fff'}} href="tel:+97143204766">+971-4-3204766</a>} 
        />
        <Footer_field 
            svg_icon={<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none"><path d="M14.75 14.75H3.5C2.125 14.75 1 13.625 1 12.25V3.5C1 2.125 2.125 1 3.5 1H14.75C16.125 1 17.25 2.125 17.25 3.5V12.25C17.25 13.625 16.125 14.75 14.75 14.75Z" stroke="#EFEFEF" strokeWidth="1.3" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/><path d="M1 6L9.125 11L17.25 6" stroke="#EFEFEF" strokeWidth="1.3" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/></svg>} 
            data={<a style={{textDecoration:'none', color: '#fff'}} href="mailto:connect@rahshiplog.com">connect@rahshiplog.com</a>} 
        />
      </div>
      </div>
    </div>
  )
}

export default Sec01