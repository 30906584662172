import React from 'react'
// import sectionImage from '../../../assets/media/services/second section image.webp'
import sectionImage from '../../../assets/media/services/Service_Section02.webp'
import Center_Section_Primary from '../../global_components/Center Section/Center_Section_Primary'
import Form_01 from '../../Forms/Form_01'

const Section02 = () => {
  return (
        <Center_Section_Primary
            src={sectionImage} 
            alt={'our services'}
            title_purple={"services"} 
            text={"With our extensive experience in logistics, we have developed an efficient optimization to offer reliable and competitive shipping services for your cargo anywhere across the globe."}
            text_box_width={"99%"}
            form={<Form_01 class={'rahship-form'}  serviceDropdown={true}/>}
        />
  )
}

export default Section02
