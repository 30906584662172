import React from "react";
import { Link } from "react-router-dom";

const Contactus_Button = (props) => {
  const buttonStyles = {
    height: props.height || "100%",
    display: "flex",
    border: "none",
    width: props.width || "190px",
    padding: "10px 30px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "15px",
    background: "#8F3055",
    color: " #FFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    textDecoration: "underline",
    textDecorationColor: "rgb(143, 48, 85)",
  };

  return (
    // <a style={{textDecoration:'none', color: '#fff', width: '100%'}} className='d-flex justify-content-center'
    //     href="tel:+97143204766">
    <Link to="/contact">
      <button style={buttonStyles}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <path
            d="M15.2402 10.9467V12.9467C15.2409 13.1324 15.2029 13.3162 15.1285 13.4863C15.0541 13.6564 14.9451 13.8091 14.8082 13.9346C14.6714 14.0602 14.5099 14.1557 14.334 14.2152C14.1581 14.2747 13.9718 14.2968 13.7869 14.2801C11.7354 14.0572 9.76486 13.3562 8.03352 12.2334C6.42274 11.2098 5.05708 9.84418 4.03352 8.2334C2.90684 6.4942 2.20569 4.51406 1.98686 2.4534C1.9702 2.26904 1.99211 2.08324 2.05119 1.90781C2.11027 1.73239 2.20524 1.57119 2.33003 1.43448C2.45483 1.29777 2.60673 1.18854 2.77605 1.11375C2.94537 1.03895 3.12842 1.00024 3.31352 1.00006H5.31352C5.63706 0.99688 5.95072 1.11145 6.19603 1.32242C6.44134 1.53339 6.60157 1.82636 6.64686 2.14673C6.73127 2.78678 6.88782 3.41522 7.11352 4.02006C7.20322 4.25868 7.22263 4.51801 7.16946 4.76732C7.11629 5.01663 6.99277 5.24547 6.81352 5.42673L5.96686 6.2734C6.91589 7.94243 8.29783 9.32436 9.96686 10.2734L10.8135 9.42673C10.9948 9.24749 11.2236 9.12396 11.4729 9.07079C11.7222 9.01762 11.9816 9.03704 12.2202 9.12673C12.825 9.35243 13.4535 9.50898 14.0935 9.5934C14.4174 9.63909 14.7131 9.8022 14.9245 10.0517C15.136 10.3013 15.2483 10.6198 15.2402 10.9467Z"
            stroke="#EFEFEF"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Contact us
      </button>
      {/* // </a> */}
    </Link>
  );
};

export default Contactus_Button;
