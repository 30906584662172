import React from 'react'
import DataCardsComponent from './DataCardsComponent'
import { Container } from 'react-bootstrap'
import Title_and_Text from '../../../global_components/Title_and_Text/Title_and_Text'

const Section04 = () => {
  return (
    <Container className='mb-global'>
        <Title_and_Text title_black={"Reasons to "} title_purple={"choose us"}/>
        <DataCardsComponent/>
    </Container>
  )
}

export default Section04
