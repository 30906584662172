import React, { useState, useEffect } from "react";
import "./header.css";
import logo from "../../../assets/media/header .png";
import Contactus_Button from "../../global_components/Contactus_Button/Contactus_Button";
import { NavLink } from "react-router-dom";
// import Mobile_Footer_Dropdown from "../Footer/Mobile_Footer_Dropdown";
import Mobile_Navlinks_dropdown from "./Mobile_Navlinks_dropdown";

const Mobile_Header = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const [activeNavItem, setActiveNavItem] = useState("home");
  const handleNavItemClick = (navItem) => {
    setActiveNavItem(navItem);
  };
  useEffect(() => {
    setActiveNavItem("home");
  }, []);

  return (
    <>
      <nav className="mobile-header">
        <button className="drawer-button" onClick={toggleDrawer}>
          {showDrawer ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18.75 5.25L5.25 18.75M18.75 18.75L5.25 5.25"
                stroke="#272727"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3.75 5.25H20.25M3.75 9.75H20.25M3.75 14.25H20.25M3.75 18.75H20.25"
                stroke="#272727"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>

        <div className="mobile-header-container">
          <a className="brand" href="/">
            <img src={logo} alt="main_logo" />
          </a>
        </div>
        <ul className={`navList ${showDrawer ? "show" : ""}`}>
          <li className="navItem">
            <NavLink className="navLink" to="/">
              Home
            </NavLink>
          </li>
          <li className="navItem">
            <NavLink className="navLink" to="/about">
              About Us
            </NavLink>
          </li>
          <li className="navItem services-navLink">
            <Mobile_Navlinks_dropdown
              label={
                <NavLink className="navLink" to="/services">
                  Services
                </NavLink>
              }
              data={
                <ul className="subNavlinks-list">
                  <li
                    className="navItem"
                    style={{ borderTop: "1px solid rgba(163, 163, 163, 0.25)" }}
                  >
                    <NavLink className="navLink" to="/sea">
                      <div>Sea Freight</div>
                    </NavLink>
                  </li>
                  <li className="navItem">
                    <NavLink className="navLink" to="/air">
                      <div>Air Freight</div>
                    </NavLink>
                  </li>
                  <li className="navItem">
                    <NavLink className="navLink" to="/land">
                      <div>Land Transportation</div>
                    </NavLink>
                  </li>
                  <li className="navItem">
                    <NavLink className="navLink" to="/lcl">
                      <div>Less Than Container Load</div>
                    </NavLink>
                  </li>
                  <li className="navItem">
                    <NavLink className="navLink" to="/customs">
                      <div>Custom Clearance</div>
                    </NavLink>
                  </li>
                  <li className="navItem">
                    <NavLink className="navLink" to="/autos">
                      <div>Automotive Logistics</div>
                    </NavLink>
                  </li>
                  <li className="navItem">
                    <NavLink className="navLink" to="/petrochem">
                      <div>Petrochem Logistics</div>
                    </NavLink>
                  </li>
                  <li className="navItem">
                    <NavLink className="navLink" to="/warehouse">
                      <div>Warehousing</div>
                    </NavLink>
                  </li>
                </ul>
              }
            />
          </li>
          <li className="navItem">
            <NavLink className="navLink" to="/e-services">
              E-Services
            </NavLink>
          </li>
          {/* <a style={{ textDecoration: "none" }} href="tel:+97143204766"> */}
          <div
            className="d-flex justify-content-center align-items-end"
            style={{ marginTop: "70px" }}
          >
            <Contactus_Button width={"100%"} />
          </div>
          {/* </a> */}
        </ul>
      </nav>
    </>
  );
};

export default Mobile_Header;
