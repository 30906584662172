import React from 'react'
import Sec01 from './Column01'
import Sec02 from './Column02'
import Sec03 from './Column03'
import "./Footer.css"

const Desktop_Footer = () => {
  return (
    <div className='desktop-footer'>
      <Sec01/>
      <Sec02/>
      <Sec03/>
    </div>
  )
}

export default Desktop_Footer
