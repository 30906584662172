import React, { useState, useEffect } from 'react';
import Desktop_Header from './Desktop_Header';
import Mobile_Header from './Mobile_Header';

const Header = () => {
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth < 992);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      }, []);

      
  
    return (
      <>
        {isMobileView ? <Mobile_Header /> : <Desktop_Header />}
      </>
    );
  }

export default Header
