import React, { useEffect } from 'react'
import Layout from '../../../Layout/Layout'
import Primary_Component from './Sub-Service Primary Component/Primary_Component'
import sectionImage from '../../../../assets/media/services/cc/custom.webp'

const CC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
    <Layout title="Custom Clearance">
        <Primary_Component
            mobileBanner_src={sectionImage}
            sectionImage_src={sectionImage}
            alt={'Customs Clearance'}
            title={'Customs Clearance'}
            text={"We take on the task of helping you reduce paperwork for customs clearance and also help prevent any delays when you're moving goods."}
            serviceName={"cc"}
        />
    </Layout>
    </div>
  )
}

export default CC
