import React from 'react'
import Title_Text from './Title_Text'
import ImportExportForm from '../../../Forms/ImportExportForm'
import ExportForm from '../../../Forms/ExportForm'

const Right_Column = () => {
  return (
    <div className='text-center'>
        <div>
            <Title_Text 
                title_white={"Our "} 
                title_purple={"E-Services"}     
                text={"Effortless Shipping Solutions: Expert Export and Import Consultants for Smooth Customs Clearance."}
                text_box_width={"475px"}
            />
        </div>
        {/* <Form_02/> */}
        <ImportExportForm/>
        {/* <ExportForm/> */}
    </div>
    
  )
}

export default Right_Column
