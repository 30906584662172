import React from "react";
import Layout from "../../Layout/Layout";
import Section011 from "./Section011";
import Section022 from "./Section022";
import { useEffect } from "react";
import '../ContactUs/ContactUsPage.css'
const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout title="Contact Us">
      <Section011 />

      <Section022 />
    </Layout>
  );
};

export default ContactUsPage;
