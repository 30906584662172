import React, { useState } from 'react';
import ImportForm from './ImportForm';
import ExportForm from './ExportForm';
import "./Forms.css";

const ImportExportForm = (props) => {
  const [activeTab, setActiveTab] = useState(1); 

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const renderForm = () => {
    switch (activeTab) {
      case 1:
        return <ImportForm/>;
      case 2:
        return <ExportForm />;
      default:
        return null;
    }
  };
  

  const getTabClassName = (tabIndex) => {
    return tabIndex === activeTab ? 'radio-option selected' : 'radio-option';
  };

  return (
      <div className='rahship-form' style={{margin: 0, overflow : 'hidden'}}>
        <div className="import-export-radio">
              <div  className={getTabClassName(1)} onClick={() => handleTabChange(1)}> Import </div>
              <span style={{content: "", width: '1px', height: '33px', background: '#C8C8C8', top: '2px', position: 'absolute'}}/>
              <div className={getTabClassName(2)} onClick={() => handleTabChange(2)}> Export </div>
         </div>
        {renderForm()}
      </div>
  );
}

export default ImportExportForm;

