import React from 'react'
import "./Footer.css"

const Footer_field= (props) => {
  return (
    <div className='d-flex'>
        <div style={{marginTop: "5px"}}>{props.svg_icon}</div>
        <p className='footer_field_data'>{props.data}</p>
    </div>
  )
}

export default Footer_field
