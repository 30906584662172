import React, { useState } from 'react';
import './Section04.css';
// import UAEport from "../../../../assets/media/home/Port1.webp";
// import PAKport from "../../../../assets/media/home/Port2.webp";
// import INDport from "../../../../assets/media/home/Port3.webp";
// import NLport from "../../../../assets/media/home/Port4.webp";
// import DEport from "../../../../assets/media/home/Port5.webp";
// import SGport from "../../../../assets/media/home/Port6.webp";

import UAEport from "../../../../assets/media/home/UAE.webp";
import PAKport from "../../../../assets/media/home/Pakistan.webp";
import INDport from "../../../../assets/media/home/India.webp";
import NLport from "../../../../assets/media/home/Netherlands.webp";
import DEport from "../../../../assets/media/home/Germany.webp";
import SGport from "../../../../assets/media/home/Singapore.webp";

const Tabs_Data = () => {
  const tabs = [
    { id: 1, label: 'UAE', image: UAEport },
    { id: 2, label: 'Pakistan', image: PAKport},
    { id: 3, label: 'India', image: INDport},
    { id: 4, label: 'Netherlands', image: NLport },
    { id: 5, label: 'germany', image: DEport },
    { id: 6, label: 'Singapore', image: SGport }
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="cc-tabs row mt-4">
      <div className="tabs col-md-3 mt-5 pt-5">
      {tabs.map((tab, index) => (
  <div
    key={tab.id}
    className={`tab ${activeTab === tab.id ? 'active' : ''}`}
    onClick={() => handleTabClick(tab.id)}
  >
    <div className="bullet">
    {activeTab === tab.id ? (
          <svg style={{verticalAlign: "text-top"}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" className="bullet-icon">
            <circle cx="7" cy="7" r="7" fill="#8F3055"/>
            <circle cx="7" cy="7" r="3" fill="white"/>
          </svg>
        ) : (
            <svg style={{verticalAlign: "text-top"}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <circle cx="7" cy="7" r="7" fill="#8F3055" fill-opacity="0.5"/>
            </svg>
        )}
    </div>
    <button className={`tab-link ${activeTab === tab.id ? 'active' : ''}`}>
      {tab.label}
    </button>
    {index < tabs.length - 1 && <div className="line" />}
  </div>
))}
      </div>
      <div className="tab-content col-md-9 d-flex align-items-center">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-pane ${activeTab === tab.id ? 'active' : ''}`}
          >
            <img className='img-fluid' src={tab.image} alt={`Our Port Operations in ${tab.label}`}/> 
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs_Data;