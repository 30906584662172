import React from "react";
import ReactDom from "react-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.min.js"
import 'react-multi-carousel/lib/styles.css';
import App from "./App";

ReactDom.render(
    <App/>
  ,document.getElementById("root")
)