import React, { useEffect } from 'react'
import Layout from '../../../Layout/Layout'
import Primary_Component from './Sub-Service Primary Component/Primary_Component'
import sectionImage from '../../../../assets/media/services/lcl/lcl.webp'

const LCL = () => {
  const titleStyle = { whiteSpace: 'pre-line' }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout title="Less Than Container Load">
        <Primary_Component
          mobileBanner_src={sectionImage}
          sectionImage_src={sectionImage}
          alt={'LCL'}
          title={ <div style={titleStyle}> Less than <br /> container load </div>}
          text={'We ensure your products get where they need to be by handling everything from the initial quote with diligence from origin to final destination.'}
          serviceName={"lcl"}
        />
    </Layout>
  )
}

export default LCL
