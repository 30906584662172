import React from "react";
import Header from "./Header/Header.jsx";
import Footer from "./Footer/Footer";
import Copyright from "./Copyright/Copyright";
import { Helmet } from "react-helmet";
import "./Layout.css";

const Layout = ({ children, title, description, name, type }) => {
  return (
    <>
      <Helmet>
        <title>RAH | {title}</title>
        <meta name='description' content="RAH | Connecting Trades | Global Logistics" />
      </Helmet>
      <Header />
      <div className="d-flex"></div>
      <main>{children}</main>
      <Footer />
      <Copyright />
      <a href="Https://wa.me/+971505895400" class="whatsapp-button">
        <img
          src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/icon_only_old.png?465/"
          alt="WhatsApp"
        />
      </a>
    </>
  );
};

export default Layout;
