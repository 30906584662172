import React from "react";
import "./header.css";
import logo from "../../../assets/media/header .png";
import Contactus_Button from "../../global_components/Contactus_Button/Contactus_Button";
import { NavLink } from "react-router-dom";

const Desktop_Header = () => {
  return (
    <>
      <nav className="desktop-header">
        <div className="cc-container">
          <a className="brand" href="/">
            <img src={logo} alt="main_logo" width={"155px"} height={"65px"} />
          </a>
          <ul className="navList">
            <li className="navItem">
              <NavLink className="navLink" to="/">
                Home
              </NavLink>
            </li>
            <li className="navItem">
              <NavLink className="navLink" to="/about">
                About Us
              </NavLink>
            </li>
            <li className="navItem services-navLink">
              <NavLink className="navLink" to="/services">
                Services{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                >
                  <path
                    d="M15.3511 0.391113H0.770508L8.06082 7.17394L15.3511 0.391113Z"
                    fill="#888"
                  />
                </svg>
              </NavLink>
              <ul className="sub-services-navLinks">
                <li className="navItem">
                  <NavLink className="navLink" to="/sea">
                    <div>Sea Freight</div>
                  </NavLink>
                </li>
                <li className="navItem">
                  <NavLink className="navLink" to="/air">
                    <div>Air Freight</div>
                  </NavLink>
                </li>
                <li className="navItem">
                  <NavLink className="navLink" to="/land">
                    <div>Land Transportation</div>
                  </NavLink>
                </li>
                <li className="navItem">
                  <NavLink className="navLink" to="/lcl">
                    <div>Less Than Container Load</div>
                  </NavLink>
                </li>
                <li className="navItem">
                  <NavLink className="navLink" to="/customs">
                    <div>Custom Clearance</div>
                  </NavLink>
                </li>
                <li className="navItem">
                  <NavLink className="navLink" to="/autos">
                    <div>Automotive Logistics</div>
                  </NavLink>
                </li>
                <li className="navItem">
                  <NavLink className="navLink" to="/petrochem">
                    <div>Petrochem Logistics</div>
                  </NavLink>
                </li>
                <li className="navItem">
                  <NavLink className="navLink" to="/warehouse">
                    <div>Warehousing</div>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="navItem">
              <NavLink className="navLink" to="/e-services">
                E-Services
              </NavLink>
            </li>
            <li>
              <Contactus_Button />
            </li>
            {/* <a style={{ textDecoration: "none" }} href="tel:+97143204766">
              <Contactus_Button />
            </a> */}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Desktop_Header;
