import React from 'react'
import Title_Text from '../Section02/Title_Text'
import Contactus_Button from '../../../global_components/Contactus_Button/Contactus_Button'

const CenterSection = () => {
  return (
    <div className='p-center-section'>
        <div>
            <Title_Text 
                title_white={"Contact Us "} 
                title_purple={"Today"}     
                text={"Have a shipment by air, sea, or truck? Contact us now! Our dedicated team ensures timely and budget-friendly delivery."}
                text_box_width={'440px'}
            />
            <div className='d-flex justify-content-center mt-3'> <Contactus_Button/> </div>
        </div>
            
    </div>
  )
}

export default CenterSection
