import React from 'react'
import Home_Banner0 from "../../../../assets/media/home/0.webp"
import Home_Banner1 from "../../../../assets/media/home/1.webp"
import Home_Banner2 from "../../../../assets/media/home/2.webp"
import Home_Banner3 from "../../../../assets/media/home/3.webp"
import Home_Banner4 from "../../../../assets/media/home/4.webp"
import Home_Banner5 from "../../../../assets/media/home/5.webp"

import Home_Banner_Mobile1 from "../../../../assets/media/home/home-mobile-banner-01.webp"
import Home_Banner_Mobile2 from "../../../../assets/media/home/home-mobile-banner-02.webp"
import Home_Banner_Mobile3 from "../../../../assets/media/home/home-mobile-banner-03.webp"
import Home_Banner_Mobile4 from "../../../../assets/media/home/home-mobile-banner-04.webp"
import Home_Banner_Mobile5 from "../../../../assets/media/home/home-mobile-banner-05.webp"
import Home_Banner_Mobile6 from "../../../../assets/media/home/home-mobile-banner-06.webp"
import '../Home.css'
import './Section01.css'
import Form_01 from '../../../Forms/Form_01'

const Section01 = () => {
  return (
    <>
      <div className="banner" style={{ position: 'relative' }}>
       
        <div id="demo" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={Home_Banner0} alt="Banner1" className="img-fluid home-desktop-banner-image"/>
              <img src={Home_Banner_Mobile1} alt="MobileBanner1" className="img-fluid home-mobile-banner-image"/>
            </div>
            <div class="carousel-item">
              <img src={Home_Banner1} alt="Banner2" className="img-fluid home-desktop-banner-image"/>
              <img src={Home_Banner_Mobile2} alt="MobileBanner2" className="img-fluid home-mobile-banner-image"/>
            </div>
            <div class="carousel-item">
              <img src={Home_Banner2} alt="Banner3" className="img-fluid home-desktop-banner-image"/>
              <img src={Home_Banner_Mobile3} alt="MobileBanner3" className="img-fluid home-mobile-banner-image"/>
            </div>
            <div class="carousel-item">
              <img src={Home_Banner3} alt="Banner4" className="img-fluid home-desktop-banner-image"/>
              <img src={Home_Banner_Mobile4} alt="MobileBanner4" className="img-fluid home-mobile-banner-image"/>
            </div>
            <div class="carousel-item">
              <img src={Home_Banner4} alt="Banner5" className="img-fluid home-desktop-banner-image"/>
              <img src={Home_Banner_Mobile5} alt="MobileBanner5" className="img-fluid home-mobile-banner-image"/>
            </div>
            <div class="carousel-item">
              <img src={Home_Banner5} alt="Banner6" className="img-fluid home-desktop-banner-image"/>
              <img src={Home_Banner_Mobile6} alt="MobileBanner6" className="img-fluid home-mobile-banner-image"/>
            </div>
          </div>

          {/*  Left and right controls/icons  */}
          <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
            <span class="carousel-control-next-icon"></span>
          </button>
        </div>  

        <div className='banner-form'>
          <Form_01 class='rahship-form-01' serviceDropdown={true}/>
        </div>
        <div className='desktop-form mx-2 mb-4' style={{marginTop: '-25px'}}>
          <Form_01 class='rahship-form-01' serviceDropdown={true}/>
        </div>
        
      </div>
    </>
  );
  
}

export default Section01
