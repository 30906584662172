import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/Home/HomePage";
import AboutUsPage from "./components/pages/AboutUs/AboutUsPage";
import ServicesPage from "./components/pages/Services/SevicesPage";
import EServicesPage from "./components/pages/E-Services/EServicesPage";
import Sea from "./components/pages/Services/Sub Services/Sea";
import Air from "./components/pages/Services/Sub Services/Air";
import Land from "./components/pages/Services/Sub Services/Land";
import LCL from "./components/pages/Services/Sub Services/LCL";
import CC from "./components/pages/Services/Sub Services/CC";
import AutomotiveLogistics from "./components/pages/Services/Sub Services/AutomotiveLogistics";
import "./index.css";
import Warehousing from "./components/pages/Services/Sub Services/Warehousing";
import PetrochemLogistics from "./components/pages/Services/Sub Services/PetrochemLogistics";
import ContactUsPage from "./components/pages/ContactUs/ContactUsPage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/sea" element={<Sea />} />
        <Route path="/air" element={<Air />} />
        <Route path="/land" element={<Land />} />
        <Route path="/lcl" element={<LCL />} />
        <Route path="/customs" element={<CC />} />
        <Route path="/autos" element={<AutomotiveLogistics />} />
        <Route path="/petrochem" element={<PetrochemLogistics />} />
        <Route path="/warehouse" element={<Warehousing />} />
        <Route path="/e-services" element={<EServicesPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
