import React from "react";
// import sectionImage from '../../../assets/media/services/second section image.webp'
// import sectionImage from "../../../assets/media/services/Service_Section02.webp";
// import Center_Section_Primary from "../../global_components/Center Section/Center_Section_Primary";
// import Form_01 from "../../Forms/Form_01";
import Title_and_Text from "../../global_components/Title_and_Text/Title_and_Text";

const Section022 = () => {
  return (
    // <Center_Section_Primary
    //   src={sectionImage}
    //   alt={"Contact us"}
    //   title_purple={"Contact us"}
    //   text={
    //     "With our extensive experience in logistics, we have developed an efficient optimization to offer reliable and competitive shipping services for your cargo anywhere across the globe."
    //   }
    //   text_box_width={"99%"}
    //   form={<Form_01 class={"rahship-form"} serviceDropdown={true} />}
    // />
    <div class="container mb-5">
      <Title_and_Text
        title_purple={"our global locations"}
        text={
          "With our extensive experience in logistics, we have developed an efficient optimization to offer reliable and competitive shipping services for your cargo anywhere across the globe."
        }
        text_box_width={"70%"}
      />

      <div class="row my-5 fontSettings">
        <div class="col-sm-6 col-md-6 col-lg-3">
          <h3>
            <strong className="contact-subtitle ">DUBAI</strong>
          </h3>
          <p className="">RAH SHIPPING & LOGISTIC LLC</p>
          <p className="d-flex align-items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-geo-alt-fill icon-margin" viewBox="0 0 16 16">
              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
            </svg>
            901, API WORLD TOWER
          </p>
          <p className="">SHEIKH ZAYED ROAD</p>
          <p className="">DUBAI, UNITED ARAB EMIRATES</p>
          <p className="d-flex align-items-center "><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-telephone-fill icon-margin" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
          </svg> +971-4-3204766</p>
          <p className="d-flex align-items-center "><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-envelope-open-fill icon-margin" viewBox="0 0 16 16">
            <path d="M8.941.435a2 2 0 0 0-1.882 0l-6 3.2A2 2 0 0 0 0 5.4v.314l6.709 3.932L8 8.928l1.291.718L16 5.714V5.4a2 2 0 0 0-1.059-1.765zM16 6.873l-5.693 3.337L16 13.372v-6.5Zm-.059 7.611L8 10.072.059 14.484A2 2 0 0 0 2 16h12a2 2 0 0 0 1.941-1.516M0 13.373l5.693-3.163L0 6.873z" />
          </svg> dxb@rahshiplog.com</p>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3 ">
          <h3>
            <strong className="contact-subtitle ">JEBEL ALI</strong>
          </h3>
          <p className="">RAH SHIPPING & LOGISTIC LLC (BRANCH)</p>
          <p className="d-flex align-items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-geo-alt-fill icon-margin" viewBox="0 0 16 16">
              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
            </svg>
            FZS/AD05, JAFZA SOUTH
          </p>
          <p className="">JEBEL ALI FREEZONE</p>
          <p className="">JEBEL ALI, UNITED ARAB EMIRATES</p>
          <p className="d-flex align-items-center "><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-telephone-fill icon-margin" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
          </svg> +971-50-1675488</p>
          <p className="d-flex align-items-center "><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-envelope-open-fill icon-margin" viewBox="0 0 16 16">
            <path d="M8.941.435a2 2 0 0 0-1.882 0l-6 3.2A2 2 0 0 0 0 5.4v.314l6.709 3.932L8 8.928l1.291.718L16 5.714V5.4a2 2 0 0 0-1.059-1.765zM16 6.873l-5.693 3.337L16 13.372v-6.5Zm-.059 7.611L8 10.072.059 14.484A2 2 0 0 0 2 16h12a2 2 0 0 0 1.941-1.516M0 13.373l5.693-3.163L0 6.873z" />
          </svg> jafza@rahshiplog.com</p>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3">
          <h3>
            <strong className="contact-subtitle ">KARACHI</strong>
          </h3>
          <p className="">RAH SHIPPING & LOGISTICS PVT LTD</p>
          <p className="d-flex align-items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-geo-alt-fill icon-margin" viewBox="0 0 16 16">
              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
            </svg>
            305, IBRAHIM TRADE TOWER
          </p>
          <p className="">SHAHRAH-E-FAISAL</p>
          <p className="">KARACHI, PAKISTAN</p>
          <p className="d-flex align-items-center "><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-telephone-fill icon-margin" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
          </svg> +92-21-34538118</p>
          <p className="d-flex align-items-center "><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-envelope-open-fill icon-margin" viewBox="0 0 16 16">
            <path d="M8.941.435a2 2 0 0 0-1.882 0l-6 3.2A2 2 0 0 0 0 5.4v.314l6.709 3.932L8 8.928l1.291.718L16 5.714V5.4a2 2 0 0 0-1.059-1.765zM16 6.873l-5.693 3.337L16 13.372v-6.5Zm-.059 7.611L8 10.072.059 14.484A2 2 0 0 0 2 16h12a2 2 0 0 0 1.941-1.516M0 13.373l5.693-3.163L0 6.873z" />
          </svg> khi@rahshiplog.com</p>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3">
          <h3>
            <strong className="contact-subtitle ">ROTTERDAM</strong>
          </h3>
          <p className="">RAH SHIPPING & LOGISTIC BV</p>
          <p className="d-flex align-items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-geo-alt-fill icon-margin" viewBox="0 0 16 16">
              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
            </svg>
            VERDIEPING 1, WILLEMSWERF
          </p>
          <p className="">BOOMPJEES 40, 3011 XB</p>
          <p className="">JEBEL ALI, UNITED ARAB EMIRATES</p>
          <p className="d-flex align-items-center "><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-telephone-fill icon-margin" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
          </svg> +31-6-13823515</p>
          <p className="d-flex align-items-center "><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-envelope-open-fill icon-margin" viewBox="0 0 16 16">
            <path d="M8.941.435a2 2 0 0 0-1.882 0l-6 3.2A2 2 0 0 0 0 5.4v.314l6.709 3.932L8 8.928l1.291.718L16 5.714V5.4a2 2 0 0 0-1.059-1.765zM16 6.873l-5.693 3.337L16 13.372v-6.5Zm-.059 7.611L8 10.072.059 14.484A2 2 0 0 0 2 16h12a2 2 0 0 0 1.941-1.516M0 13.373l5.693-3.163L0 6.873z" />
          </svg> rtm@rahshiplog.com</p>
        </div>
      </div>
    </div>
  );
};

export default Section022;
