import React from 'react'
import { Image } from 'react-bootstrap'
import '../EServices.css'


const Card = (props) => {
  return (
        <div className='section02-card'>
            <div className='image-box'> <Image src={props.src} alt={props.alt}/> </div>
            <div>
                <div className='title'>{props.title}</div>
                <div className='text'>{props.text}</div>
            </div>
        </div>
  )
}

export default Card
