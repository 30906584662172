import React from 'react'

const Title_Text = (props) => {

  const title_style = {
    color: '#272727',
    fontFamily:' Anybody-bold',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    textTransform: 'uppercase',
  }

  const title_style_span = { color: '#8F3055' }

  const text_box_style = {
    color: '#5F5F5F',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px', 
    width : props.text_box_width || '100%'
  }

  return (
    <div className='d-flex justify-content-center'>
      <div className='text-center center-section'>
        <div style={title_style} className='title'>
          {props.title_white}
          <span style={title_style_span}> {props.title_purple} </span> 
        </div>
        <div className='d-flex justify-content-center mt-1'>
          <div style={text_box_style} className='text'>{props.text}</div>
        </div>
      </div>
    </div>
  )
}

export default Title_Text





