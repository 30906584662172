import React from 'react'
import "../Home.css"
import CenterSection from './CenterSection'

const Section03 = () => {
  return (
    <div className='home-section-03'>
      <CenterSection/>
    </div>
  )
}

export default Section03
